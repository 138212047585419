import React from "react";
import FlagProvider, { type UnleashClient } from "@unleash/proxy-client-react";
import FlagsChecker from "./components/FlagsChecker";

interface FeatureFlagsProviderProps {
    children: React.ReactElement;
    unleashClient: UnleashClient;
    startClient?: boolean;
}

const FeatureFlagsProvider = ({
    children,
    unleashClient,
    startClient = true,
}: FeatureFlagsProviderProps) => (
    <FlagProvider unleashClient={unleashClient} startClient={startClient}>
        <FlagsChecker>{children}</FlagsChecker>
    </FlagProvider>
);

export default FeatureFlagsProvider;
