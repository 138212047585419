import { trackPromise } from "react-promise-tracker";
import { useMutation } from "react-query";
import accountService from "../AccountService";
import AccountServiceError from "../AccountServiceError";

const useSendEmailVerification = () => {
    const { isLoading, isSuccess, isError, mutateAsync, reset } = useMutation<
        void,
        AccountServiceError,
        void
    >(() => trackPromise(accountService.sendEmailVerification()));

    const sendEmailVerification = () => mutateAsync();

    return {
        sendEmailVerification,
        isLoading,
        isSuccess,
        isError,
        clearSendEmailVerificationState: reset,
    };
};

export default useSendEmailVerification;
