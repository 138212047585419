import { trackPromise } from "react-promise-tracker";
import { useMutation } from "react-query";
import accountService from "../AccountService";
import AccountServiceError from "../AccountServiceError";
import type { UpdateAccountDetails } from "../types";

interface UpdateLanguageVariables {
    preferredLanguageCode: string;
}

const useUpdateLanguage = () => {
    const { isLoading, isSuccess, isError, mutateAsync, reset } = useMutation<
        UpdateAccountDetails,
        AccountServiceError,
        UpdateLanguageVariables
    >((variables) => {
        const { preferredLanguageCode } = variables;

        return trackPromise(
            accountService.updateAccount({
                preferredLanguageCode,
            })
        );
    });

    const updateLanguage = (preferredLanguageCode: string) =>
        mutateAsync({ preferredLanguageCode });

    return {
        updateLanguage,
        isLoading,
        isSuccess,
        isError,
        clearUpdateLanguageState: reset,
    };
};

export default useUpdateLanguage;
