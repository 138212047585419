/**
 * @fileoverview gRPC-Web generated client stub for account
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.17.3
// source: account.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as account_pb from './account_pb'; // proto import: "account.proto"
import * as account_common_pb from './account_common_pb'; // proto import: "account_common.proto"
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb'; // proto import: "google/protobuf/empty.proto"


export class AccountsClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAuthenticate = new grpcWeb.MethodDescriptor(
    '/account.Accounts/Authenticate',
    grpcWeb.MethodType.UNARY,
    account_pb.AuthenticationRequest,
    account_pb.AuthenticationResponse,
    (request: account_pb.AuthenticationRequest) => {
      return request.serializeBinary();
    },
    account_pb.AuthenticationResponse.deserializeBinary
  );

  authenticate(
    request: account_pb.AuthenticationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<account_pb.AuthenticationResponse>;

  authenticate(
    request: account_pb.AuthenticationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.AuthenticationResponse) => void): grpcWeb.ClientReadableStream<account_pb.AuthenticationResponse>;

  authenticate(
    request: account_pb.AuthenticationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.AuthenticationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.Accounts/Authenticate',
        request,
        metadata || {},
        this.methodDescriptorAuthenticate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.Accounts/Authenticate',
    request,
    metadata || {},
    this.methodDescriptorAuthenticate);
  }

  methodDescriptorThirdPartyAuthenticate = new grpcWeb.MethodDescriptor(
    '/account.Accounts/ThirdPartyAuthenticate',
    grpcWeb.MethodType.UNARY,
    account_pb.ThirdPartyAuthenticationRequest,
    account_pb.ThirdPartyAuthenticationResponse,
    (request: account_pb.ThirdPartyAuthenticationRequest) => {
      return request.serializeBinary();
    },
    account_pb.ThirdPartyAuthenticationResponse.deserializeBinary
  );

  thirdPartyAuthenticate(
    request: account_pb.ThirdPartyAuthenticationRequest,
    metadata?: grpcWeb.Metadata | null): Promise<account_pb.ThirdPartyAuthenticationResponse>;

  thirdPartyAuthenticate(
    request: account_pb.ThirdPartyAuthenticationRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.ThirdPartyAuthenticationResponse) => void): grpcWeb.ClientReadableStream<account_pb.ThirdPartyAuthenticationResponse>;

  thirdPartyAuthenticate(
    request: account_pb.ThirdPartyAuthenticationRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.ThirdPartyAuthenticationResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.Accounts/ThirdPartyAuthenticate',
        request,
        metadata || {},
        this.methodDescriptorThirdPartyAuthenticate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.Accounts/ThirdPartyAuthenticate',
    request,
    metadata || {},
    this.methodDescriptorThirdPartyAuthenticate);
  }

  methodDescriptorCreateAccount = new grpcWeb.MethodDescriptor(
    '/account.Accounts/CreateAccount',
    grpcWeb.MethodType.UNARY,
    account_pb.CreateAccountRequest,
    account_pb.CreateAccountResponse,
    (request: account_pb.CreateAccountRequest) => {
      return request.serializeBinary();
    },
    account_pb.CreateAccountResponse.deserializeBinary
  );

  createAccount(
    request: account_pb.CreateAccountRequest,
    metadata?: grpcWeb.Metadata | null): Promise<account_pb.CreateAccountResponse>;

  createAccount(
    request: account_pb.CreateAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.CreateAccountResponse) => void): grpcWeb.ClientReadableStream<account_pb.CreateAccountResponse>;

  createAccount(
    request: account_pb.CreateAccountRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.CreateAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.Accounts/CreateAccount',
        request,
        metadata || {},
        this.methodDescriptorCreateAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.Accounts/CreateAccount',
    request,
    metadata || {},
    this.methodDescriptorCreateAccount);
  }

  methodDescriptorGetAccount = new grpcWeb.MethodDescriptor(
    '/account.Accounts/GetAccount',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    account_common_pb.GetAccountResponse,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    account_common_pb.GetAccountResponse.deserializeBinary
  );

  getAccount(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<account_common_pb.GetAccountResponse>;

  getAccount(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_common_pb.GetAccountResponse) => void): grpcWeb.ClientReadableStream<account_common_pb.GetAccountResponse>;

  getAccount(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_common_pb.GetAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.Accounts/GetAccount',
        request,
        metadata || {},
        this.methodDescriptorGetAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.Accounts/GetAccount',
    request,
    metadata || {},
    this.methodDescriptorGetAccount);
  }

  methodDescriptorUpdateAccount = new grpcWeb.MethodDescriptor(
    '/account.Accounts/UpdateAccount',
    grpcWeb.MethodType.UNARY,
    account_pb.UpdateAccountRequest,
    google_protobuf_empty_pb.Empty,
    (request: account_pb.UpdateAccountRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  updateAccount(
    request: account_pb.UpdateAccountRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  updateAccount(
    request: account_pb.UpdateAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  updateAccount(
    request: account_pb.UpdateAccountRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.Accounts/UpdateAccount',
        request,
        metadata || {},
        this.methodDescriptorUpdateAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.Accounts/UpdateAccount',
    request,
    metadata || {},
    this.methodDescriptorUpdateAccount);
  }

  methodDescriptorUpdateEmail = new grpcWeb.MethodDescriptor(
    '/account.Accounts/UpdateEmail',
    grpcWeb.MethodType.UNARY,
    account_pb.UpdateEmailRequest,
    account_pb.UpdateEmailResponse,
    (request: account_pb.UpdateEmailRequest) => {
      return request.serializeBinary();
    },
    account_pb.UpdateEmailResponse.deserializeBinary
  );

  updateEmail(
    request: account_pb.UpdateEmailRequest,
    metadata?: grpcWeb.Metadata | null): Promise<account_pb.UpdateEmailResponse>;

  updateEmail(
    request: account_pb.UpdateEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.UpdateEmailResponse) => void): grpcWeb.ClientReadableStream<account_pb.UpdateEmailResponse>;

  updateEmail(
    request: account_pb.UpdateEmailRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.UpdateEmailResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.Accounts/UpdateEmail',
        request,
        metadata || {},
        this.methodDescriptorUpdateEmail,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.Accounts/UpdateEmail',
    request,
    metadata || {},
    this.methodDescriptorUpdateEmail);
  }

  methodDescriptorUpdatePassword = new grpcWeb.MethodDescriptor(
    '/account.Accounts/UpdatePassword',
    grpcWeb.MethodType.UNARY,
    account_pb.UpdatePasswordRequest,
    account_pb.UpdatePasswordResponse,
    (request: account_pb.UpdatePasswordRequest) => {
      return request.serializeBinary();
    },
    account_pb.UpdatePasswordResponse.deserializeBinary
  );

  updatePassword(
    request: account_pb.UpdatePasswordRequest,
    metadata?: grpcWeb.Metadata | null): Promise<account_pb.UpdatePasswordResponse>;

  updatePassword(
    request: account_pb.UpdatePasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: account_pb.UpdatePasswordResponse) => void): grpcWeb.ClientReadableStream<account_pb.UpdatePasswordResponse>;

  updatePassword(
    request: account_pb.UpdatePasswordRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: account_pb.UpdatePasswordResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.Accounts/UpdatePassword',
        request,
        metadata || {},
        this.methodDescriptorUpdatePassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.Accounts/UpdatePassword',
    request,
    metadata || {},
    this.methodDescriptorUpdatePassword);
  }

  methodDescriptorForgotPassword = new grpcWeb.MethodDescriptor(
    '/account.Accounts/ForgotPassword',
    grpcWeb.MethodType.UNARY,
    account_pb.ForgotPasswordRequest,
    google_protobuf_empty_pb.Empty,
    (request: account_pb.ForgotPasswordRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  forgotPassword(
    request: account_pb.ForgotPasswordRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  forgotPassword(
    request: account_pb.ForgotPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  forgotPassword(
    request: account_pb.ForgotPasswordRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.Accounts/ForgotPassword',
        request,
        metadata || {},
        this.methodDescriptorForgotPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.Accounts/ForgotPassword',
    request,
    metadata || {},
    this.methodDescriptorForgotPassword);
  }

  methodDescriptorResetPassword = new grpcWeb.MethodDescriptor(
    '/account.Accounts/ResetPassword',
    grpcWeb.MethodType.UNARY,
    account_pb.ResetPasswordRequest,
    google_protobuf_empty_pb.Empty,
    (request: account_pb.ResetPasswordRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  resetPassword(
    request: account_pb.ResetPasswordRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  resetPassword(
    request: account_pb.ResetPasswordRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  resetPassword(
    request: account_pb.ResetPasswordRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.Accounts/ResetPassword',
        request,
        metadata || {},
        this.methodDescriptorResetPassword,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.Accounts/ResetPassword',
    request,
    metadata || {},
    this.methodDescriptorResetPassword);
  }

  methodDescriptorVerifyEmail = new grpcWeb.MethodDescriptor(
    '/account.Accounts/VerifyEmail',
    grpcWeb.MethodType.UNARY,
    account_pb.VerifyEmailRequest,
    google_protobuf_empty_pb.Empty,
    (request: account_pb.VerifyEmailRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  verifyEmail(
    request: account_pb.VerifyEmailRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  verifyEmail(
    request: account_pb.VerifyEmailRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  verifyEmail(
    request: account_pb.VerifyEmailRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.Accounts/VerifyEmail',
        request,
        metadata || {},
        this.methodDescriptorVerifyEmail,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.Accounts/VerifyEmail',
    request,
    metadata || {},
    this.methodDescriptorVerifyEmail);
  }

  methodDescriptorSendEmailVerification = new grpcWeb.MethodDescriptor(
    '/account.Accounts/SendEmailVerification',
    grpcWeb.MethodType.UNARY,
    google_protobuf_empty_pb.Empty,
    google_protobuf_empty_pb.Empty,
    (request: google_protobuf_empty_pb.Empty) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  sendEmailVerification(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  sendEmailVerification(
    request: google_protobuf_empty_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  sendEmailVerification(
    request: google_protobuf_empty_pb.Empty,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.Accounts/SendEmailVerification',
        request,
        metadata || {},
        this.methodDescriptorSendEmailVerification,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.Accounts/SendEmailVerification',
    request,
    metadata || {},
    this.methodDescriptorSendEmailVerification);
  }

  methodDescriptorDeactivateAccount = new grpcWeb.MethodDescriptor(
    '/account.Accounts/DeactivateAccount',
    grpcWeb.MethodType.UNARY,
    account_pb.DeactivateAccountRequest,
    google_protobuf_empty_pb.Empty,
    (request: account_pb.DeactivateAccountRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  deactivateAccount(
    request: account_pb.DeactivateAccountRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  deactivateAccount(
    request: account_pb.DeactivateAccountRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  deactivateAccount(
    request: account_pb.DeactivateAccountRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/account.Accounts/DeactivateAccount',
        request,
        metadata || {},
        this.methodDescriptorDeactivateAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/account.Accounts/DeactivateAccount',
    request,
    metadata || {},
    this.methodDescriptorDeactivateAccount);
  }

}

