import { trackPromise } from "react-promise-tracker";
import { useMutation } from "react-query";
import accountService from "../AccountService";
import AccountServiceError from "../AccountServiceError";

interface DeactivateAccountVariables {
    email: string;
    password: string;
}

const useDeactivateAccount = () => {
    const { isLoading, isSuccess, isError, mutateAsync, reset } = useMutation<
        void,
        AccountServiceError,
        DeactivateAccountVariables
    >((variables) => {
        const { email, password } = variables;

        return trackPromise(accountService.deactivateAccount(email, password));
    });

    const deactivateAccount = (email: string, password: string) => mutateAsync({ email, password });

    return {
        deactivateAccount,
        isLoading,
        isSuccess,
        isError,
        clearDeactivateAccountState: reset,
    };
};

export default useDeactivateAccount;
