import React, { Suspense } from "react";
import "./components/bootstrap.css";
import log from "loglevel";
import { createRoot } from "react-dom/client";
import { pdfjs } from "react-pdf";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Preloader from "./components/common/Preloader/Preloader";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import "./index.css";
import { NodeEnvironmentKind } from "./constants/nodeEnvironmentKind";
import reactAppEnvironment from "./constants/reactAppEnvironment";
import AppProviders from "./provider/AppProviders";
import registerServiceWorker from "./registerServiceWorker";
import { i18NextInit, getI18NexusUrls } from "./services/i18n";
// Removes related react-pdf warnings and hides the pdf text-only layer
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

/* Hack to make sure this Promise.withResolvers polyfill gets included.
   Something about the Storybook Upgrade (MVP-8132) caused the polyfill
   to not get included. Likely a change in an underlying polyfill babel-plugin
   used by CRACO.
 */
export * from "core-js/proposals/promise-with-resolvers";

/*
 * Set up pdfjs worker, which is required for react-pdf to work
 * Hack: process.env.NODE_ENV is used here to get the preview site to use the appropriate worker source.
 * Without it, the preview site would break.
 */
pdfjs.GlobalWorkerOptions.workerSrc =
    process.env.NODE_ENV === NodeEnvironmentKind.Production
        ? "/static/js/pdf.worker.min.mjs" // This is copied over during the build process in craco.config.js
        : new URL("pdfjs-dist/legacy/build/pdf.worker.min.mjs", import.meta.url).toString();

const logLevel =
    process.env.REACT_APP_ENVIRONMENT === reactAppEnvironment.production
        ? log.levels.WARN
        : log.levels.DEBUG;
log.setLevel(logLevel);

const i18NexusUrl =
    process.env.REACT_APP_ENVIRONMENT === reactAppEnvironment.production
        ? getI18NexusUrls().prod
        : getI18NexusUrls().dev;
i18NextInit(i18NexusUrl);

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const root = createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <BrowserRouter basename={baseUrl}>
            <Suspense fallback="loading">
                <ErrorBoundary>
                    <AppProviders>
                        <App />
                        <Preloader />
                    </AppProviders>
                </ErrorBoundary>
            </Suspense>
        </BrowserRouter>
    </React.StrictMode>
);

registerServiceWorker();
