import { trackPromise } from "react-promise-tracker";
import { useMutation } from "react-query";
import accountService from "../AccountService";

interface ResetPasswordVariables {
    token: string;
    password: string;
}

const useResetPassword = () => {
    const { isLoading, isSuccess, isError, mutateAsync, reset } = useMutation<
        void,
        Error,
        ResetPasswordVariables
    >((variables) => {
        const { token, password } = variables;
        return trackPromise(accountService.resetPassword(token, password));
    });

    const resetPassword = (token: string, password: string) =>
        mutateAsync({
            token,
            password,
        });

    return {
        resetPassword,
        isLoading,
        isSuccess,
        isError,
        clearResetPasswordState: reset,
    };
};

export default useResetPassword;
