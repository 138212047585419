import { useCallback } from "react";
import { trackPromise } from "react-promise-tracker";
import { useMutation } from "react-query";
import accountService from "../AccountService";
import AccountServiceError from "../AccountServiceError";

interface VerifyEmailVariables {
    token: string;
}

const useVerifyEmail = () => {
    const { isLoading, isSuccess, isError, error, mutateAsync, reset } = useMutation<
        void,
        AccountServiceError,
        VerifyEmailVariables
    >((variables) => trackPromise(accountService.verifyEmail(variables.token)));

    const verifyEmail = useCallback((token: string) => mutateAsync({ token }), [mutateAsync]);

    return {
        verifyEmail,
        isLoading,
        isSuccess,
        isError,
        error,
        clearVerifyEmailState: reset,
    };
};

export default useVerifyEmail;
