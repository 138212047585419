import is from "@sindresorhus/is";
import { isValid, isMatch } from "date-fns";
import ow from "ow";
import postalCodes from "postal-codes-js";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import languages from "../constants/languages";
import passwordConstants from "../constants/passwordConstants";
import { ParticipantKind } from "../models/participantKind";

const ValidEmailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

/**
 * Verifies if the provided email has the correct format.
 * @param {string} candidate - The email address.
 * @returns {boolean} - Boolean that indicates whether the email is valid or not.
 */
const isValidEmail = (candidate) => ValidEmailRegex.test(candidate);

/**
 * Verifies if the language provided is supported.
 * @param {string} languageKey - The language.
 * @returns {boolean} - Boolean that indicates whether the language provided is supportedor not.
 */
const isValidLanguage = (languageKey) => !is.nullOrUndefined(languages[languageKey]);

/**
 * Verifies if the provided phone number has the correct format.
 * @param {string?} [candidate] - The phone number.
 * @returns {boolean} - Boolean that indicates whether the phone number is valid or not.
 */
const validatePhoneNumber = (candidate) =>
    !is.nullOrUndefined(candidate) && isPossiblePhoneNumber(candidate);

/**
 * Validates zip code.
 * @param {string | null} [zipCode] - Zip code
 * @param {string | null} [countryCode] - Country code
 * @returns {boolean} - True for a valid zip code
 */
const validateZipCode = (zipCode, countryCode = "US") =>
    is.string(zipCode) &&
    is.string(countryCode) &&
    postalCodes.validate(countryCode, zipCode) === true;

/**
 * Verifies if the provided address is valid.
 * @param {object} candidate - The address.
 * @returns {boolean} - Boolean that indicates whether the address is valid or not.
 */
const validateAddress = (candidate) =>
    !is.nullOrUndefined(candidate) &&
    is.nonEmptyStringAndNotWhitespace(candidate.street) &&
    is.nonEmptyStringAndNotWhitespace(candidate.city) &&
    is.nonEmptyStringAndNotWhitespace(candidate.state) &&
    validateZipCode(candidate.zipCode);

/**
 * Verifies if the provided name is a valid full name.
 * @param {object} candidate - The name.
 * @returns {boolean} - Boolean that indicates whether the name is a valid full name or not.
 */
const validateFullName = (candidate) =>
    !is.nullOrUndefined(candidate) &&
    is.nonEmptyStringAndNotWhitespace(candidate.firstName) &&
    is.nonEmptyStringAndNotWhitespace(candidate.lastName);

/**
 * Validates the date.
 * @param {Date|string} date - the date to validate or the empty string (returns false).
 * @returns {boolean} - True for a valid date. False for empty string or invalid date.
 */
const validateDate = (date) => {
    if (is.emptyString(date)) {
        return false;
    }
    return isValid(date);
};

/**
 * Verifies if the provided date string is valid.
 * @param {string | null | undefined} candidate - The date string.
 * @returns {boolean} - Boolean that indicates whether the date string is valid.
 */
const validateDateString = (candidate) => isMatch(candidate, "yyyy-MM-dd");

/**
 * Verifies if the provided password is valid.
 * @param {string} candidate - The password.
 * @returns {object} - Boolean that indicates whether the date string is valid.
 */
const validatePassword = (candidate) => {
    const passwordRules = [
        {
            test: (value) =>
                is.nullOrUndefined(value)
                    ? false
                    : value.length >= passwordConstants.MINIMUM_LENGTH,
            message: "password.minimum_length_requirement",
        },
        {
            test: (value) => new RegExp("[a-z]").test(value),
            message: "password.lowercase_requirement",
        },
        {
            test: (value) => new RegExp("[A-Z]").test(value),
            message: "password.uppercase_requirement",
        },
        {
            test: (value) => new RegExp("[0-9]").test(value),
            message: "password.number_requirement",
        },
        {
            test: (value) => new RegExp("[ -/:-@[-`{-~]").test(value),
            message: "password.symbol_requirement",
        },
        {
            test: (value) =>
                is.nullOrUndefined(value)
                    ? false
                    : value.length <= passwordConstants.MAXIMUM_LENGTH,
            message: "password.maximum_length_requirement",
            hideIfValid: true,
        },
    ];

    let passwordPassedAllChecks = true;
    const passwordValidations = passwordRules.map((rule) => {
        let isValid = rule.test(candidate);
        if (passwordPassedAllChecks && !isValid) {
            passwordPassedAllChecks = false;
        }
        return { isValid: isValid, message: rule.message, hideIfValid: rule.hideIfValid };
    });
    return {
        isValid: passwordPassedAllChecks,
        passwordValidations: passwordValidations,
    };
};

const isStringWithContent = is.nonEmptyStringAndNotWhitespace;

/**
 * Verifies if the provided participant kind is valid.
 * @param {string} participantKind - The participant kind.
 * @returns {boolean} - Boolean that indicates whether the participant kind is valid.
 */
const isValidParticipantKind = (participantKind) =>
    [ParticipantKind.Plaintiff, ParticipantKind.Defendant].includes(participantKind);

/**
 * Verifies if 2 addresses are the same.
 * @param {null|{street: ?string, street2: ?string, city: ?string, state: ?string, zipCode: ?string}} address1 - The address1 object.
 * @param {null|{street: ?string, street2: ?string, city: ?string, state: ?string, zipCode: ?string}} address2 - The address2 object.
 * @returns {boolean} Returns true if the address1 or address2 are null or if the address1 and address2 are equal; false otherwise.
 */
const areAddressesEqual = (address1, address2) => {
    if (is.nullOrUndefined(address1) || is.nullOrUndefined(address2)) {
        return true;
    }
    ow(
        address1,
        ow.object.exactShape({
            street: ow.any(ow.null, ow.optional.string),
            street2: ow.any(ow.null, ow.optional.string),
            city: ow.any(ow.null, ow.optional.string),
            state: ow.any(ow.null, ow.optional.string),
            zipCode: ow.any(ow.null, ow.optional.string),
        })
    );
    ow(
        address2,
        ow.object.exactShape({
            street: ow.any(ow.null, ow.optional.string),
            street2: ow.any(ow.null, ow.optional.string),
            city: ow.any(ow.null, ow.optional.string),
            state: ow.any(ow.null, ow.optional.string),
            zipCode: ow.any(ow.null, ow.optional.string),
        })
    );

    return (
        address1.street === address2.street &&
        address1.street2 === address2.street2 &&
        address1.city === address2.city &&
        address1.state === address2.state &&
        address1.zipCode === address2.zipCode
    );
};

export {
    isValidEmail,
    isValidLanguage,
    validatePhoneNumber,
    validateZipCode,
    validateAddress,
    validateFullName,
    validateDate,
    validateDateString,
    validatePassword,
    isStringWithContent,
    isValidParticipantKind,
    areAddressesEqual,
};
