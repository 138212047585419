import { useLocation } from "react-router-dom";
import referrerSources from "../constants/referrerSources";
import questUIStrings from "../constants/strings";

/**
 * Boolean that indicates whether a custom layout should be used
 * @returns {boolean} custom layout boolean
 */
const useIsCustomLayout = () => {
    const { pathname } = useLocation();

    return (
        pathname.includes(referrerSources.cccba) || pathname.includes(questUIStrings.peopleClerk)
    );
};

export default useIsCustomLayout;
