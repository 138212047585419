import { Empty } from "google-protobuf/google/protobuf/empty_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import {
    BoolValue,
    BytesValue,
    DoubleValue,
    Int32Value,
    Int64Value,
    StringValue,
} from "google-protobuf/google/protobuf/wrappers_pb";

export default class GrpcHelpers {
    static buildEmpty() {
        return new Empty();
    }

    static buildBoolValue(data) {
        let boolValue;
        if (data !== undefined && data !== null) {
            boolValue = new BoolValue();
            boolValue.setValue(data);
        }

        return boolValue;
    }

    static buildInt32Value(data) {
        let int32Value;
        if (data !== undefined && data !== null) {
            int32Value = new Int32Value();
            int32Value.setValue(data);
        }

        return int32Value;
    }

    static buildDoubleValue(data) {
        let doubleValue;
        if (data !== undefined && data !== null) {
            doubleValue = new DoubleValue();
            doubleValue.setValue(data);
        }

        return doubleValue;
    }

    static buildInt64Value(data) {
        let int64Value;
        if (data !== undefined && data !== null) {
            int64Value = new Int64Value();
            int64Value.setValue(data);
        }

        return int64Value;
    }

    static buildStringValue(data) {
        let stringValue;
        if (data !== undefined && data !== null) {
            stringValue = new StringValue();
            stringValue.setValue(data);
        }

        return stringValue;
    }

    static buildBytesValue(data) {
        let bytesValue;
        if (data !== undefined && data !== null) {
            bytesValue = new BytesValue();
            bytesValue.setValue(data);
        }

        return bytesValue;
    }

    static buildTimestamp(data) {
        let timestamp;
        if (data !== undefined && data !== null) {
            timestamp = new Timestamp();
            timestamp.setSeconds(data.seconds);
            timestamp.setNanos(data.nanos);
        }

        return timestamp;
    }
}
