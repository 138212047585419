// List of URLs
export default {
    caCourts: "https://www.courts.ca.gov/1011.htm",
    caHelpCenter: "https://www.courts.ca.gov/selfhelp.htm?rdeLocaleAttr=en",
    caHelpCenterSmallClaims: "https://www.courts.ca.gov/selfhelp-smallclaims.htm?rdeLocaleAttr=en",
    caAttorney: "https://www.calbar.ca.gov/Public/Need-Legal-HelpV",
    fw001: "https://www.courts.ca.gov/documents/fw001.pdf",
    int300: "https://www.courts.ca.gov/documents/int300.pdf",
    mc005: "https://www.courts.ca.gov/documents/mc005.pdf",
    mc030: "https://www.courts.ca.gov/documents/mc030.pdf",
    mc410: "https://www.courts.ca.gov/documents/mc410.pdf",
    sc104: "https://www.courts.ca.gov/documents/sc104.pdf",
    sc109: "https://www.courts.ca.gov/documents/sc109.pdf",
    sc104b: "https://www.courts.ca.gov/documents/sc104b.pdf",
    sc104c: "https://www.courts.ca.gov/documents/sc104c.pdf",
    sc101: "https://www.courts.ca.gov/documents/sc101.pdf",
    sc120: "https://www.courts.ca.gov/documents/sc120.pdf",
    sc150: "https://www.courts.ca.gov/documents/sc150.pdf",
    civ110: "https://www.courts.ca.gov/documents/civ110.pdf",
    casePresentationVideo: "https://www.youtube.com/embed/qUjsKuTe0pA",
    approvedVendorFile:
        "https://www.abclegal.com/abc-legal-justice-direct-file?hs_preview=jrpMhjGj-63301251099",
    approvedVendorServe:
        "https://www.abclegal.com/abc-legal-justice-direct-file-0?hs_preview=SAUaugDu-65010434445",
    q4jHelpCenter: "https://help.justicedirect.com/",
    q4jEducation: "https://www.justicedirect.com/education",
    googleClientLibrary: "https://accounts.google.com/gsi/client",
    googleOAuth2API: "https://oauth2.googleapis.com",
    ser001: "https://www.courts.ca.gov/documents/ser001.pdf",
    devPeopleClerkRedirectDomain: "portal.q4justice.dev",
    prodPeopleClerkRedirectDomain: "portal.justicedirect.com",
    devPeopleClerkLoginPage: "https://portal.dev.peopleclerk.com/auth/login",
    prodPeopleClerkLoginPage: "https://portal.peopleclerk.com/auth/login",
    peopleClerkLoginPage: "https://portal.peopleclerk.com/auth/login",
    termsOfUse: "https://justicedirect.com/terms-of-use",
    privacyPolicy: "https://justicedirect.com/privacy-policy",
} as const;
